import React from "react";
import NavigationBar from "../Features/Navigation";

export default function Announcements() {
  return (
    <div>
      <NavigationBar />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Tournament Announcements</h2>

        <div className="card mb-3">
          <div className="card-header bg-primary text-white">Match Details</div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              Match 1: Nizamiye-7 vs Springs-4
            </li>
            <li className="list-group-item">
              Match 2: Nizamye-7 vs Roshnee-10
            </li>
            <li className="list-group-item">Match 3: Nizamiye-8 vs LMS B-5</li>
            <li className="list-group-item">Match 4: Nizamiye-8 vs APAX-5</li>
          </ul>
        </div>

        <div className="card mb-3">
          <div className="card-header bg-success text-white">
            Quarter Finals
          </div>
          <div className="card-body">
            <p className="card-text">Nizamiye-8 vs Al Huda-4</p>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header bg-info text-white">Semi Finals</div>
          <div className="card-body">
            <p className="card-text">Nizamiye-9 vs LMS A-6</p>
          </div>
        </div>

        <div className="card">
          <div className="card-header bg-danger text-white">Finals</div>
          <div className="card-body">
            <p className="card-text">Nizamiye-10 vs Azaadville-7</p>
          </div>
        </div>
      </div>
    </div>
  );
}
