import { Container } from "react-bootstrap";
import SignUp from "./components/Auth/SignUp";
import { AuthProvider } from "./contexts/AuthContexts";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Pages/Dashboard";
import Chat from "./components/Pages/Chat";
import Resources from "./components/Pages/Resources";
import Announcements from "./components/Pages/Announcements";
import UpdateProfile from "./components/Auth/UpdateProfile";
import Login from "./components/Auth/Login";
import PrivateRoute from "./components/Auth/PrivateRoute";
import Profile from "./components/Auth/Profile";
import ForgotPassword from "./components/Auth/ForgotPassword";

function App() {
  return (
    <div className="App">
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vh" }}
      >
        <AuthProvider>
          <Routes>
            <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
            <Route
              path="/update-profile"
              element={<PrivateRoute Component={UpdateProfile} />}
            />
            <Route
              path="/dashboard"
              element={<PrivateRoute Component={Dashboard} />}
            />
            <Route path="/chat" element={<PrivateRoute Component={Chat} />} />
            <Route
              path="/resources"
              element={<PrivateRoute Component={Resources} />}
            />
            <Route
              path="/announcements"
              element={<PrivateRoute Component={Announcements} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute Component={Profile} />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </AuthProvider>
      </Container>
    </div>
  );
}

export default App;
