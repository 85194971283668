import React from "react";
import NavigationBar from "../Features/Navigation";

export default function Resources() {
  return (
    <div>
      <NavigationBar></NavigationBar>
      <h2>Resources</h2>
    </div>
  );
}
