import React from "react";
import { useAuth } from "../../contexts/AuthContexts";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ Component }) => {
  const { currentUser } = useAuth();
  return currentUser ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
