// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCj6dA9QQRmlvG4kgcWLQ1BXED-Q7JlhJM",
  authDomain: "studentcircle-c0c84.firebaseapp.com",
  projectId: "studentcircle-c0c84",
  storageBucket: "studentcircle-c0c84.appspot.com",
  messagingSenderId: "332892525183",
  appId: "1:332892525183:web:579b54d1b8e5a3fd7e654a",
  measurementId: "G-7D3NQLSD39",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;
