import React, { useRef, useState, useEffect } from "react";
import NavigationBar from "../Features/Navigation";
import {
  Button,
  Container,
  Row,
  Modal,
  Spinner,
  Form,
  Alert,
  Card,
  Col,
  Image,
} from "react-bootstrap";
import {
  setDoc,
  doc,
  getDocs,
  collection,
  updateDoc,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase"; // Ensure you import the storage reference
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContexts";

export default function Dashboard() {
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false); // For showing the details modal
  const [selectedHomework, setSelectedHomework] = useState(null);
  const [selectedHomeworkImg, setSelectedHomeworkImg] = useState(null);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [homeworks, setHomeworks] = useState([]);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null); // For storing the uploaded file
  const { currentUser } = useAuth();
  const subject = useRef();

  useEffect(() => {
    const fetchHomework = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "homework"));
        const homeworkData = [];
        const images = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          if (data.imageUrl) {
            const imageRef = ref(storage, data.imageUrl);
            const imageUrl = await getDownloadURL(imageRef); // Fetch URL\
            images.push(imageUrl);
            console.log(imageUrl);
            homeworkData.push({ id: doc.id, imageUrl, ...data }); // Push to array after fetching URL
          } else {
            homeworkData.push({ id: doc.id, ...data });
          }
        }
        setImages(images);
        setHomeworks(homeworkData); // Set state once all data is prepared
        console.log(homeworkData); // Log the final stat
      } catch (error) {
        console.error("Error fetching homework: ", error);
      }
    };

    fetchHomework();
  }, [homeworks]);
  const handleDeleteHomework = async () => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "homework", selectedHomework.id)); // Delete the document
      const updatedHomeworks = homeworks.filter(
        (h) => h.id !== selectedHomework.id
      ); // Update the local state
      setHomeworks(updatedHomeworks);
      setShowDetailsModal(false); // Close the modal after deletion
      setLoading(false);
    } catch (error) {
      setError("Failed to delete homework");
      setLoading(false);
    }
  };
  const handleAddComment = async () => {
    if (!comment || !selectedHomework) return; // Prevent empty comments or if no homework is selected
    try {
      const homeworkRef = doc(db, "homework", selectedHomework.id);
      await updateDoc(homeworkRef, {
        comments: arrayUnion({
          author: currentUser.email,
          content: comment,
          timestamp: new Date(),
        }),
      });
      setComment("");
      // Update local state to reflect new comment
      setSelectedHomework({
        ...selectedHomework,
        comments: [
          ...(selectedHomework.comments || []),
          {
            author: currentUser.email,
            content: comment,
            timestamp: new Date().toISOString(),
          },
        ],
      });
    } catch (error) {
      setError("Failed to add comment");
    }
  };

  const handleAddHomework = async () => {
    const newDocRef = doc(collection(db, "homework"));
    try {
      setLoading(true);
      const imagePath = `images/${newDocRef.id}/${file.name}`;
      const imageRef = ref(storage, imagePath);
      await uploadBytes(imageRef, file); // Upload the file

      await setDoc(newDocRef, {
        studentEmail: currentUser.email,
        title: title,
        description: description,
        grade: subject.current.value,
        imageUrl: imagePath, // Store image path in Firestore
      });
      setLoading(false);
      setShowModal(false);
      setTitle("");
      setDescription("");
      setFile(null); // Reset file
      // Refresh the list of homeworks
      const querySnapshot = await getDocs(collection(db, "homework"));
      const homeworkData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHomeworks(homeworkData);
    } catch (error) {
      setError("Failed to add homework");
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Capture the file from the input
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedHomework(null);
  };
  const handleCardClick = (homework, index) => {
    setSelectedHomework(homework);
    setShowDetailsModal(true);
    setSelectedHomeworkImg(index);
  };

  return (
    <div>
      <NavigationBar />
      <Container
        style={{
          marginTop: "20px",
          backgroundColor: "#f8f9fa",
          width: "500px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Row className="mb-3">
          <Button onClick={() => setShowModal(true)}>Add Homework</Button>
        </Row>
        <Col className="g-4">
          {homeworks.map((homework, index) => (
            <Row
              key={homework.id}
              onClick={() => handleCardClick(homework, index)}
            >
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <Card.Title>{homework.title}</Card.Title>
                  <Card.Text>{homework.description}</Card.Text>
                  {homework.imageUrl && (
                    <img
                      src={images[index]}
                      alt="Homework"
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                  <Card.Footer>
                    <small>Subject: {homework.grade}</small>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Row>
          ))}
        </Col>
      </Container>

      <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedHomework?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Img variant="top" src={images[selectedHomeworkImg]} />
            <Card.Body>
              <Card.Title>{selectedHomework?.title}</Card.Title>
              <Card.Text>{selectedHomework?.description}</Card.Text>
              <Card.Text>
                <small className="text-muted">
                  Subject: {selectedHomework?.grade}
                </small>
              </Card.Text>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add a comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <Row>
                    <Button
                      variant="primary"
                      onClick={handleAddComment}
                      className="mt-2"
                    >
                      Submit Comment
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleDeleteHomework}
                      className="mt-2"
                    >
                      Delete Homework
                    </Button>
                  </Row>
                </Form.Group>
                {selectedHomework?.comments?.map((c, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#f0f0f0",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <strong>{c.author}</strong>: {c.content}
                  </div>
                ))}
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload your Homework Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group id="grade" className="mt-2 mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Select aria-label="Default select example" ref={subject}>
                <option>Select Subject</option>
                <option value="Physics">Physics</option>
                <option value="Maths">Maths</option>
                <option value="Biology">Biology</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} required />
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleAddHomework}
              className="mt-3"
              disabled={loading || !file}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Upload"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
