import React, { useState } from "react";
import { Button, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navigation.css";

const NavigationBar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="lg" fixed="top" style={{ backgroundColor: "#0d6efd" }}>
        <Navbar.Brand className="navBrand" href="/">
          <p>Study Circle</p>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="cont">
            <Link to={"/"}>Dashboard</Link>
            <Link to={"/chat"}>Chat</Link>
            <Link to={"/announcements"}>Announcements</Link>
            <Link to={"/profile"}>Profile</Link>
            <Button variant="outline-primary" onClick={handleShow}>
              Tips
            </Button>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            <li>
              <h4>Create a dedicated study space</h4>
              <p>
                Designate a specific area for studying to help condition your
                mind to focus when you're in that space. Ensure it's well-lit,
                free from distractions, and has all the necessary supplies.
              </p>
            </li>
            <li>
              <h4>Use a planner or digital calendar</h4>
              <p>
                Keep track of your assignments, deadlines, and exams by using a
                planner or a digital calendar. This helps ensure you never miss
                deadlines and can effectively manage your time.
              </p>
            </li>
            <li>
              <h4>Practice active learning</h4>
              <p>
                Engage with the material through discussion, teaching others, or
                applying the concepts in practice problems. Active learning
                helps improve retention and understanding.
              </p>
            </li>
            <li>
              <h4>Take regular breaks</h4>
              <p>
                Follow the Pomodoro Technique or a similar method to take breaks
                regularly, such as a 5-minute break every 25 minutes. This can
                help maintain consistent performance and focus throughout your
                study sessions.
              </p>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavigationBar;
