import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const generateApiKey = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = Array.from(
      { length },
      () => characters[Math.floor(Math.random() * characters.length)]
    ).join("");
    return result;
  };

  async function signup(email, pswd, name, surname, grade) {
    // First check if the company exists
    const studentRef = doc(db, "students", email.toLowerCase());
    const docSnap = await getDoc(studentRef);
    if (docSnap.exists()) {
      throw new Error("Student already exists");
    }

    const cred = await createUserWithEmailAndPassword(auth, email, pswd);
    try {
      await setDoc(doc(db, "students", email.toLowerCase()), {
        name: name,
        surname: surname,
        grade: grade,
      });
      return cred;
    } catch (e) {
      console.error("Error :", e);
      throw e;
    }
  }

  function login(email, pswd) {
    return signInWithEmailAndPassword(auth, email, pswd);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateUserEmail(email) {
    return updateEmail(currentUser, email);
  }

  function updateUserPassword(pswd) {
    return updatePassword(currentUser, pswd);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
