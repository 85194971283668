import React, { useState } from "react";
import NavigationBar from "../Features/Navigation";
import { GoogleGenerativeAI } from "@google/generative-ai";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./Chat.css"; // Assuming you store your styles in Chat.css

export default function Chat() {
  const genAI = new GoogleGenerativeAI(
    "AIzaSyCxy2baWBqCGc2Lb95KBg5EPi1vu2uqzKU"
  );
  const model = genAI.getGenerativeModel({ model: "gemini-pro" });
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const sendMessage = async () => {
    if (!input.trim()) return;
    const userMessage = {
      id: messages.length,
      text: input,
      sender: "user",
    };
    setMessages([...messages, userMessage]);
    setLoading(true);

    try {
      const prompt = input;
      const result = await model.generateContent(prompt);
      const text = await result.response.text();
      const botMessage = {
        id: messages.length + 1,
        text: text,
        sender: "bot",
      };
      setMessages((msgs) => [...msgs, botMessage]);
    } catch (error) {
      const errorMessage = {
        id: messages.length + 1,
        text: "Sorry, there was an error processing your request.",
        sender: "bot",
      };
      setMessages((msgs) => [...msgs, errorMessage]);
    } finally {
      setLoading(false);
    }

    setInput(""); // Clear input field after sending
  };

  return (
    <div>
      <NavigationBar />
      <div className="chat-container">
        <h1>Chat with StudyCircle AI</h1>
        <div className="chat-box">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <ReactMarkdown
                children={message.text}
                remarkPlugins={[remarkGfm]}
              />
            </div>
          ))}
          {loading && <p className="loading">Loading...</p>}
        </div>
        <div className="message-input">
          <input
            value={input}
            onChange={handleChange}
            placeholder="Type your message..."
          />
          <button onClick={sendMessage} disabled={loading}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
