import React, { useState, useEffect } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContexts";
import { db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import NavigationBar from "../Features/Navigation";

export default function Profile() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const companyRef = doc(db, "clients", currentUser.email);
  const [apiKey, setApiKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUrls = async () => {
      const docSnap = await getDoc(companyRef);
      setApiKey(docSnap.data().apiKey); //Set the company name
    };

    fetchUrls().catch(console.error);
  }, [companyRef]);

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <div>
      <NavigationBar />
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>Email : </strong>
          {currentUser.email}
          <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </div>
  );
}
