import React, { useRef, useState } from "react";
import { Card, Form, Button, Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContexts";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp() {
  const emailRef = useRef();
  const pswdRef = useRef();
  const pswdConfirmRef = useRef();
  const nameRef = useRef();
  const surnameRef = useRef();
  const gradeRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    if (pswdRef.current.value !== pswdConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setLoading(true);
      await signup(
        emailRef.current.value,
        pswdRef.current.value,
        nameRef.current.value,
        surnameRef.current.value,
        gradeRef.current.value
      );
      navigate("/");
    } catch {
      setError("Failed to create an account");
    }
    setLoading(false);
  }

  return (
    <div style={{ width: "500px" }}>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4" style={{ color: "#0d6efd" }}>
            Sign Up
          </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="tetx" ref={nameRef} required></Form.Control>
            </Form.Group>

            <Form.Group id="surname">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                type="text"
                ref={surnameRef}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group id="grade" className="mt-2 mb-3">
              <Form.Label>Grade</Form.Label>
              <Form.Select aria-label="Default select example" ref={gradeRef}>
                <option>Select Grade</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Form.Select>
            </Form.Group>

            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required></Form.Control>
            </Form.Group>

            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={pswdRef}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group id="password-confirma" className="mb-2">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={pswdConfirmRef}
                required
              ></Form.Control>
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Sign Up"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </div>
  );
}
